<template>
<router-view />
</template>

<script>
export default{
    
    data() {
        return {

    }}
}

</script>

<style> 

@import '@vueform/slider/themes/default.css';

@font-face {
    font-family: "GothamPro-Regular";
    src: local("GothamPro-Regular"),
        url(@/assets/fonts/GothamPro-Regular.ttf)
}

@font-face {
    font-family: "GothamPro-Bold";
    src: local("GothamPro-Bold"),
        url(@/assets/fonts/GothamPro-Bold.ttf)
}

@font-face {
    font-family: "GothamPro-Light";
    src: local("GothamPro-Light"),
        url(@/assets/fonts/GothamPro-Light.ttf)
}

* {
    font-family: "GothamPro-Regular";
    padding: 0;
    margin: 0;

}

#app {
    height: 100%
}



html,
body{

    position: relative;
    height: 100%;
}



ul {
    list-style: none;
}

a {
    text-decoration: none;
}

.input-error {
    font-size: 0.9rem;
    margin-top: 0.5em;
    color: #EF5C4E;
}

input:focus{
    outline: none;
}

/* 768x1024 */
@media(max-width: 768px) {
    *{
      -webkit-tap-highlight-color: transparent;  
    }

    .input-error {
        font-size: 0.8rem;
    }
}    
</style>